/**
 * View Switcher
 * 
 * @dependencies
 * * jquery
 * * kmcookie
 * 
 * @dependents
 * * $(document).ready(function()
 * * listener.start();
 * * data attribute [data-toggle="toggle-view"] and [data-view] used in several templates in cms-backend
 * * [data-toggle="toggle-view"] and [data-view] used as selector in style.less in lightscout
 * 
 * @commit
 * * s.schiffer 2014-12
 * * Initial commit 2.0
 * * Bug Fixing v.schaefer 2019-01-28
 */


var view_switch = new Object();

view_switch.start = function () {
  if ($('[data-toggle="toggle-view"]').length || $('[data-toggle="tab"]').length) {

    $('[data-toggle="toggle-view"] a').on('click', function (event) {
      event.preventDefault();
      var self = $(this).parent();
      var target = self.parent().attr('data-parent');
      var num = self.index();

      self.addClass('active').siblings().removeClass('active');
      $(target + ' [data-view]').attr('data-view', num);

      // Haben wir Isotope? Dann nach switch intialisieren ss 201600901
      // Auskommentiert, da das Layout kaputt geht, wenn isotope hier initialisiert wird
      // Konzeptionell nicht gut da hier auf eine Globale Variable zugegriffen wird,
      // die in einer anderen Funktion in einer anderen Datei deklariert wird  //vs 20190128
      //  $container.isotope();
      // }

      view_switch.save(self, target, num);
    });

    // Unterscheidung für Case: Anwendungsbilder
    $('[data-toggle="tab"]').on('click', function (event) {
      event.preventDefault();
      var self = $(this).parent();
      var target = self.parent().attr('data-parent');
      var num = self.index();

      // Es handelt sich nur um ein Grid-Switcher, wenn das Elternelement ein data-parent enthält
      if (target) {
        view_switch.save(self, target, num);
      }
    });
  }
}

// Wird aufgerufen, wenn die Ansicht geändert werden soll.
// @param obj caller (das Listenelement welches geklickt wurde)
view_switch.save = function (caller, target, num) {
  // Cookie setzen
  var cookie = kmcookie.lesen('erco-grid-view-style');
  var data = JSON.parse(cookie);

  if (data) {
    var found = false;

    // Schauen ob die ID bereits gesetzt wurde und ggf. überschreiben
    for (var i = 0; i < data.length; i++) {
      if (data[i].id == target) {
        data[i].view_style = num;
        found = true;
        break;
      }
    }

    // ID ist noch nicht vorhanden. Neuen Datensatz hinzufügen
    if (!found) {
      data.push({
        'id': target,
        'view_style': num
      });
    }
  }
  // Es sind noch keine Daten vorhanden. Erstelle ein leeres Array mit dem 1. Datensatz
  else {
    data = new Array({
      'id': target,
      'view_style': num
    });
  }

  kmcookie.setzen('erco-grid-view-style', JSON.stringify(data), 43200); // 30 Tage
}

// Setzt die Listenansicht auf die ggf. im Cookie gesetzten Werte
view_switch.setDefaultView = function () {
  var cookie = kmcookie.lesen('erco-grid-view-style');
  var data = JSON.parse(cookie);

  if (data) {
    $('* [data-parent]').each(function (index, el) {
      var data_parent = $(el).attr('data-parent');

      for (var i = 0; i < data.length; i++) {
        // Nur durchlaufen, wenn im Cookie gespeichert
        if (data[i].id == data_parent) {
          // Umschalter durchlaufen
          $("li", el).each(function (index) {
            // Viewstyle = n-tes Listitem
            if (data[i].view_style == index) {
              // View für Anwendungsbilder triggern
              if (data[i].id.match(/application-images/)) {
                $("a", this).trigger("click");
                $(this).trigger("click");
              }
              // View für Produktbilder umschalten
              else {
                $(data[i].id + " [data-view]").attr('data-view', index);
              }

              // dem <li> die Klasse active geben
              $(this).addClass('active');
            }
            else {
              $(this).removeClass('active');
            }
          });
        }
      }
    });
  }
}