/* contact.js
Klickmeister GmbH für ERCO GmbH
02.2019
*/

var contact_map = {

  geocoder: null,
  map: null,
  marker: null,
  startpos: null ,
  startZoom: 5,
  MY_MAPTYPE_ID: 'erco',
  icons: '/struktur/builds/sprite-basic.svg',
  countryList: null,
  currentISO: null,
  nierlasssungen: null,
  markerListe: [],  
  // custom Mapstyles -> http://gmaps-samples-v3.googlecode.com/svn/trunk/styledmaps/wizard/index.html
  mapstyle: [ 
    { 
      featureType: "water", 
      elementType: "all", 
      stylers: [ 
        { lightness: 99 },
        { saturation: -100 }, 
        { visibility: "simplified" } 
      ] 
    },
    { 
      featureType: "administrative", 
      elementType: "labels", 
      stylers: [ 
        { visibility: "off" } 
      ] 
    },
    { 
      featureType: "road", 
      elementType: "all", 
      stylers: [ 
        { visibility: "off" } 
      ] 
    },
    { 
      featureType: "poi",
      elementType: "all", 
      stylers: [ 
        { visibility: "off" } 
      ] 
    },
    { 
      featureType: "landscape", 
      elementType: "all", 
      stylers: [ 
        { saturation: -100 }, 
        { lightness: -13 } 
      ] 
    },
    { 
      featureType: "administrative",
      elementType: "all", 
      stylers: [ 
        { visibility: "off" } 
      ] 
    },
    { 
      featureType: "administrative.country", 
      elementType: "all", 
      stylers: [ 
        { visibility: "on" } 
      ] 
    },
    { 
      featureType: "administrative.country", 
      elementType: "labels", 
      stylers: [ 
        { visibility: "off" } 
      ] 
    },
    { 
      featureType: "transit", 
      elementType: "all", 
      stylers: [ 
        { visibility: "off" } 
      ] 
    },
    { 
      featureType: "landscape", 
      elementType: "all", 
      stylers: [ 
        { visibility: "off" } 
      ] 
    } 
  ],
  mapStyleZoomedOut: [ 
    { 
      featureType: "water", 
      elementType: "all", 
      stylers: [ 
        { lightness: 99 }, 
        { saturation: -100 }, 
        { visibility: "simplified" } 
      ] 
    },
    { 
      featureType: "administrative", 
      elementType: "labels", 
      stylers: [ 
        { visibility: "off" } 
      ] 
    },
    { 
      featureType: "road", 
      elementType: "all", 
      stylers: [ 
        { visibility: "off" } 
      ] 
    },
    { 
      featureType: "poi", 
      elementType: "all", 
      stylers: [
        { visibility: "off" } 
      ] 
    },
    { 
      featureType: "landscape", 
      elementType: "all", 
      stylers: [ 
        { saturation: -100 }, 
        { lightness: -13 } 
      ] 
    },
    { 
      featureType: "administrative", 
      elementType: "all", 
      stylers: [ 
        { visibility: "off" } 
      ] 
    },
    { 
      featureType: "administrative.country",
      elementType: "all", 
      stylers: [ 
        { visibility: "on" } 
      ] 
    },
    { 
      featureType: "administrative.country", 
      elementType: "labels", 
      stylers: [ 
        { visibility: "off" } 
      ] 
    },
    { 
      featureType: "transit", 
      elementType: "all", 
      stylers: [ 
        { visibility: "off" }
      ] 
    },
    { 
      featureType: "landscape", 
      elementType: "all", 
      stylers: [ 
        { visibility: "off" } 
      ] 
    } 
  ],
  mapStyleZoomedIn: [
    { 
      featureType: "water", 
      elementType: "all", 
      stylers: [
        { lightness: 99 },
        { saturation: -100 },
        { visibility: "simplified" }
      ] 
    },
    { 
      featureType: "administrative", 
      elementType: "labels", 
      stylers: [
        { visibility: "on" }
      ] 
    },
    { 
      featureType: "road", 
      elementType: "all", 
      stylers: [
        { visibility: "off" }
      ] 
    },
    { 
      featureType: "poi", 
      elementType: "all", 
      stylers: [
        { visibility: "off" }
      ] 
    },
    { 
      featureType: "landscape", 
      elementType: "all", 
      stylers: [
        { saturation: -100 },
        { lightness: -13 }
      ] 
    },
    { 
      featureType: "administrative", 
      elementType: "all", 
      stylers: [
        { visibility: "on" }
      ] 
    },
    { 
      featureType: "administrative.country", 
      elementType: "all", 
      stylers: [
        { visibility: "on" }
      ] 
    },
    { 
      featureType: "administrative.country", 
      elementType: "labels", 
      stylers: [
        { visibility: "on" }
      ] 
    },
    { 
      featureType: "administrative.province", 
      elementType: "labels", 
      stylers: [
        { visibility: "off" }
      ] 
    },
    { 
      featureType: "administrative.locality", 
      elementType: "labels", 
      stylers: [
        { visibility: "off" }
      ] 
    },
    { 
      featureType: "administrative.neighborhood", 
      elementType: "labels", 
      stylers: [
        { visibility: "off" }
      ] 
    },
    { 
      featureType: "administrative.land_parcel", 
      elementType: "labels", 
      stylers: [
        { visibility: "off" }
      ] 
    },
    { 
      featureType: "transit", 
      elementType: "all", 
      stylers: [
        { visibility: "off" }
      ] 
    },
    { 
      featureType: "landscape", 
      elementType: "all", 
      stylers: [
        { visibility: "off" }
      ] 
    }
  ],
  mapStyleZoomedIn_full:[
    { 
      featureType: "water", 
      elementType: "all", 
      stylers: [
        { lightness: 99 },
        { saturation: -100 },
        { visibility: "simplified" }
      ] 
    },
    { 
      featureType: "administrative", 
      elementType: "labels", 
      stylers: [
        { visibility: "on" }
      ] 
    },
    { 
      featureType: "road", 
      elementType: "all", 
      stylers: [
        { visibility: "off" }
      ] 
    },
    { 
      featureType: "poi", 
      elementType: "all", 
      stylers: [
        { visibility: "off" }
      ] 
    },
    { 
      featureType: "landscape", 
      elementType: "all", 
      stylers: [
        { saturation: -100 },
        { lightness: -13 }
      ] 
    },
    { 
      featureType: "administrative", 
      elementType: "all", 
      stylers: [
        { visibility: "on" }
      ] 
    },
    { 
      featureType: "administrative.country", 
      elementType: "all", 
      stylers: [
        { visibility: "on" }
      ] 
    },
    { 
      featureType: "administrative.country", 
      elementType: "labels", 
      stylers: [
        { visibility: "on" }
      ] 
    },
    { 
      featureType: "administrative.province", 
      elementType: "labels", 
      stylers: [
        { visibility: "on" }
      ] 
    },
    { 
      featureType: "administrative.locality", 
      elementType: "labels", 
      stylers: [
        { visibility: "on" }
      ] 
    },
    { 
      featureType: "administrative.neighborhood", 
      elementType: "labels", 
      stylers: [
        { visibility: "off" }
      ] 
    },
    { 
      featureType: "administrative.land_parcel", 
      elementType: "labels", 
      stylers: [
        { visibility: "off" }
      ] 
    },
    { 
      featureType: "transit", 
      elementType: "all", 
      stylers: [
        { visibility: "off" }
      ] 
    },
    { 
      featureType: "landscape", 
      elementType: "all", 
      stylers: [
        { visibility: "off" }
      ] 
    }
  ],
  mapOptions: {
		zoom: this.startZoom,
		center: this.startpos,
		mapTypeControlOptions: {mapTypeIds: [ this.MY_MAPTYPE_ID]},
		mapTypeId: this.MY_MAPTYPE_ID,
		disableDefaultUI: true, 
		minZoom:2,
		maxZoom:10,
		backgroundColor: '#dddddd',
		keyboardShortcuts:true,
		scrollwheel: false
  },

  init() {
    contact_map.startpos = new google.maps.LatLng(32.35444293518239, 2.7830156249999805);
    contact_map.geocoder = new google.maps.Geocoder();
    //Google Map erstellen 
    
    contact_map.map = new google.maps.Map(document.getElementById("map_canvas"),contact_map.mapOptions);

    // mapstyles für die zoomstufen
    var styledMapOptions = {map: "ERCO", name: 'minimial'}; 
    var styledMapOptions2 = {map: "ERCO", name: 'maximial'}; 
    var styledMapOptions3 = {map: "ERCO", name: 'maximial_full'}; 
    
    var sMapType = new google.maps.StyledMapType(contact_map.mapStyleZoomedOut,styledMapOptions); 
    contact_map.map.mapTypes.set('minimal', sMapType); 
    contact_map.map.setMapTypeId('minimal'); 
    
    var sMapType2 = new google.maps.StyledMapType(contact_map.mapStyleZoomedIn,styledMapOptions2); 
    contact_map.map.mapTypes.set('maximial', sMapType2);
    
    var sMapType3 = new google.maps.StyledMapType(contact_map.mapStyleZoomedIn_full,styledMapOptions3); 
    contact_map.map.mapTypes.set('maximial_full', sMapType3);
    
    // style werchseln wenn zoom wechselt
    google.maps.event.addListener(contact_map.map, 'zoom_changed', function() { 
      var zoomLevel = contact_map.map.getZoom();
      var sMapType;
      
      // städte
      if(zoomLevel==8) {
        contact_map.map.setMapTypeId('maximial_full');
      }
      // länder
      else if(zoomLevel>5) {
        contact_map.map.setMapTypeId('maximial');
      }
      else {
        contact_map.map.setMapTypeId('minimal'); 
      }
    });

    //Listener
    google.maps.event.addListener(contact_map.map, 'click', function(event) {
      contact_map.encodePosition(event);
    });
    
    //Custom Buttons hinzufügen
    var customControlDiv = document.createElement('customControl');
    var customControlNav = contact_map.customController(customControlDiv, contact_map.map);
    
    // customControlNav.index = 1;
    contact_map.map.controls[google.maps.ControlPosition.TOP_LEFT].push(customControlDiv);
    
    contact_map.setNiederlassungen();

    $('#check-position').on('click', function(){
      contact_map.check_input_position();
    });

    // Laendername wird in inputfeld aktualisiert
    $('.nolist').find('a').on('click', function(e){
      $('#standort').attr('placeholder', $(this).parent().attr('title'));
    });
  
    contact_map.get_all_countries();
    contact_map.wo_bin_ich();
    
    // da sonst der erste akkordion ausgefahren wird.
    setTimeout(function(){
      $('.vertrieb-collapse-tu.collapse-tu.-active').removeClass('-active');
      $('.collapse-container.-open').removeClass('-open');
      $('.icon.newicon-collapse.rotate').removeClass('rotate');
    }, 50);
  },

  setNiederlassungen(){
    $.ajax({
      url: confEnv['pdb_api_url'] +  '/contact/get_settlements_json',
      method: 'GET',
      cache: false,
      dataType: 'json',
      success: function (data) {
        contact_map.niederlassungen = data.niederlassungen;
        //Marker erstellen
        for(i=0;i<contact_map.niederlassungen.length;i++){
          contact_map.createMarker(i,contact_map.niederlassungen[i][1],contact_map.niederlassungen[i][2], contact_map.niederlassungen[i][0]);
        }
      }, error: function (err){
        console.log(err);
      }
    });
  },
  
  encodePosition(mousePos) {
    var lng = mousePos.latLng.lng();
    var lat = mousePos.latLng.lat();

    var latlng = new google.maps.LatLng(lat, lng);
    contact_map.map.panTo(latlng);
      
    if (contact_map.geocoder) {
      contact_map.geocoder.geocode({'latLng': latlng}, function(results, status) {
        if (status == google.maps.GeocoderStatus.OK) { 
          if(results[0]){
            for(result=0;result<results.length;result++) {
              var laenge = results[result].address_components.length;
          
              for(i=0;i<laenge;i++){
                if(results[result].address_components[i].types[0]=="country"){
                  var countryISO = results[result].address_components[i]["short_name"];
                }
              }
            }
            laendersuche.land_zeigen( countryISO );
          }
        } else {
          //alert("Keine daten für diese Position verfügbar: " + status);
        }
      });
    }
  },

  createMarker(marker_id,lat,lang, sprache){ 
    if(typeof google == 'undefined') {
      return;
    }
    // Marker daten
    var myLatLng = new google.maps.LatLng(lat, lang);
    //var markerImageFile = "/cdn/maps/images/marker_niederlassung.svg";
    var markerImageFile = new google.maps.MarkerImage('/struktur/builds/sprite-basic.png', new google.maps.Size(11,11), new google.maps.Point(3480, 0));
    
    
    var marker = new google.maps.Marker({
      position: myLatLng,
      map: contact_map.map,
      id: marker_id,
      icon: markerImageFile,
      lang: sprache
    });
  
    // Hier werden die Marker gespiechert, damit in wo_bin_ich() der jeweilige Marker farblich anders gekennzeichnet werden kann. 
    contact_map.markerListe.push(marker);
   
    google.maps.event.addListener(marker, 'click', function() {
      contact_map.setContactForm(contact_map.niederlassungen[this.id][0]);
      contact_map.updateMarker(this.lang);
      contact_map.get_country_name(this.lang);
      laendersuche.land_zeigen(false, contact_map.niederlassungen[this.id][0] );
      //Marker auf Karte zentrieren
      contact_map.pan_map_to(contact_map.niederlassungen[this.id][1],contact_map.niederlassungen[this.id][2]);
    });
  },
  
  setContactForm(iso) {
    $.ajax({
      url: confEnv['pdb_api_url'] +  '/contact/get_contact_json/' + iso,
      method: 'GET',
      cache: false,
      dataType: 'json',
      success: function (data) {

        // remove old phone-number and set new number
        const salesPhoneElement = document.getElementById('sales-phone');
        salesPhoneElement.removeChild(salesPhoneElement.firstChild);
        const strongTagPhone = document.createElement('strong');
        strongTagPhone.innerText = data.fon;
        salesPhoneElement.appendChild(strongTagPhone);
        salesPhoneElement.setAttribute('href', `tel: ${data.fon}`);

        // remove old email and set new one
        const salesEmailElement = document.getElementById('sales-email');
        salesEmailElement.removeChild(salesEmailElement.firstChild);
        const strongTagEmail = document.createElement('strong');
        strongTagEmail.innerText = data.email;
        salesEmailElement.appendChild(strongTagEmail);
        salesEmailElement.setAttribute('href', `mailto: ${data.email}`);

      }, error: function (err){
        console.log(err);
      }
    });
  },
  
  customController(controlDiv,map){	
		
		controlDiv.style.padding = '5px';

		//////////////////////////////////////////////////////////////////////////////
    //plus-Button
		var controlUI_plus = document.createElement('DIV');
		controlUI_plus.style.cursor = 'pointer';
		controlUI_plus.title = 'zoom in';
		
		controlDiv.appendChild(controlUI_plus);

		// Set CSS for the control interior
	 	var controlText = document.createElement('DIV');
	
	 	controlText.innerHTML = '<span class="newicon-maps-plus"></span>';
    controlUI_plus.appendChild(controlText);
	  	
    //////////////////////////////////////////////////////////////////////////////
    //minus-Button
    var controlUI_minus = document.createElement('DIV');
		controlUI_minus.style.cursor = 'pointer';
		controlUI_minus.title = 'zoom in';
		
		controlDiv.appendChild(controlUI_minus);
		
		
		// Set CSS for the control interior
	 	var controlText = document.createElement('DIV');
	
	 	controlText.innerHTML = '<span class="newicon-maps-minus"></span>';
    controlUI_minus.appendChild(controlText);


		google.maps.event.addDomListener(controlUI_plus, 'click', function() {
			var currentZoom = map.getZoom();
			map.setZoom(currentZoom+1);
			//console.log("zooming to: "+(currentZoom+1));
		});
			
		google.maps.event.addDomListener(controlUI_minus, 'click', function() {
			var currentZoom = map.getZoom();
			map.setZoom(currentZoom-1);
			//console.log("zooming to: "+(currentZoom-1));
		});
  },

  check_input_position(){
    contact_map.countryList.liste.forEach(function(e){
      if(e.name.toLowerCase() == $('#standort').val().toLowerCase()){
        $('#check-position').attr('disabled', true);
        $('#standort').val('');
        $('#standort').attr('placeholder', e.name);
        Dropdown.clear();
        Dropdown.hide();
        contact_map.updateMarker(e.iso);
        contact_map.setContactForm(e.iso);
        contact_map.currentISO = e.iso;

  
        laendersuche.land_zeigen( 'ohne_scrolling', e.iso );
        contact_map.pan_map_to(e.lat,e.lng);

        if(contact_map.map !== null) {
          contact_map.map.setZoom(parseInt(e.zoom));
        }
      }
    });
  },

  wo_bin_ich(){
    //Geolokalisierte Daten ziehen
    $.ajax({
      url:  confEnv['pdb_api_url'] + '/contact/get_contact_json/',
      method: 'GET',
      cache: false,
      dataType: 'json',
      success: function (data) {
  
        //Anfürhungszeichen vorne und hinten innerhalb der Variablen entfernen
        var iso = data.iso.replace(/'/g, '');

        contact_map.currentISO = iso;
        contact_map.get_country_name(iso);

        contact_map.updateMarker(iso);

        laendersuche.land_zeigen( 'ohne_scrolling', iso );
        contact_map.pan_map_to(data.lat,data.lng);
        
        if(contact_map.map !== null) {
          contact_map.map.setZoom(parseInt(data.zoom));
        }
      },
      error: function (err) {
        elements[i].innerHTML = 'error';
        console.error(err);
      }
    });
  },

  updateMarker (iso) {
    var isokomplett = iso;
    var iso = iso.substring(0, 2);
    
    contact_map.markerListe.forEach(function(e){
      contact_map.setMarkerIcon(e, 11, 3480, 0);
      if(e.lang == "de"){
        // wegen Haupstandort soll dieser Punkt immer gesondert dargestellt werden
        contact_map.setMarkerIcon(e, 13, 3480, 30);
      }
    });

    // highlight Position auf karte (orange)
    var temp = contact_map.markerListe.filter(function(e){
      if(e.lang.startsWith(iso.toLowerCase())) return e;
    });	

    temp.forEach(function(el){
      if(el.lang == isokomplett.toLowerCase()){
        contact_map.setMarkerIcon(el, 13, 3480, 60);
      } else {
        contact_map.setMarkerIcon(el, 11, 3512, 1);

        if(el.lang == "de"){
          // wegen Haupstandort soll dieser Punkt immer gesondert dargestellt werden
          contact_map.setMarkerIcon(el, 13, 3511, 30);
        }
      }
    });
  },
  
  get_all_countries (){
    // liste der jeweiligen länder
    $.ajax({
      url: confEnv['pdb_api_url'] +  '/contact/get_countries_json/' + k3vars.S_Sprache,
      method: 'GET',
      cache: false,
      dataType: 'json',
      success: function (data) {
        contact_map.countryList = data;
        Dropdown.init();
        
      }, error: function (err){
        console.log(err);
      }
    });
  },
  
  get_country_name(iso){
    if(contact_map.countryList == null){
      setTimeout(function(){
        contact_map.get_country_name(iso);
      }, 100);
    } else {
      contact_map.countryList.liste.forEach(function(e){
        if(e.iso == iso.toUpperCase()){
          $('#standort').attr('placeholder', e.name);
        }
      });
    }
  },
  
  checkIEVersion() {
    var sAgent = window.navigator.userAgent;
    var Idx = sAgent.indexOf("MSIE");
  
    // If IE, return version number.
    if (Idx > 0) 
      contact_map.icons = '/struktur/builds/sprite-basic.png';
  
    // If IE 11 then look for Updated user agent string.
    else if (!!navigator.userAgent.match(/Trident\/7\./)) {
      contact_map.icons = '/struktur/builds/sprite-basic.png';
    }
  },
  
  setMarkerIcon(marker, sizeXY, pointX, pointY) {
    if(typeof google == 'undefined') {
      return;
    }
    var markerImageFile = new google.maps.MarkerImage(contact_map.icons , new google.maps.Size(sizeXY,sizeXY), new google.maps.Point(pointX, pointY));
    marker.setIcon(markerImageFile);
  },
  
  pan_map_to(lat,lng){
    if(typeof google == 'undefined') {
      return;
    }
    var latlng = new google.maps.LatLng(lat, lng);
    contact_map.map.panTo(latlng);
  }
};


const laendersuche = {
  laendersuche_last_entry: false,
  laendermittelpunkte: false,
  
  set_laendermittelpunkte(id) {
    if(!laendersuche.laendermittelpunkte){
      $.ajax({
        url: confEnv['pdb_api_url'] +  '/contact/get_latlng_countries_json',
        method: 'GET',
        cache: false,
        dataType: 'json',
        success: function (data) {
          laendersuche.laendermittelpunkte = data.laendermittelpunkte;
          contact_map.pan_map_to(laendersuche.laendermittelpunkte[id][0],laendersuche.laendermittelpunkte[id][1]);
          contact_map.map.setZoom(4);
        }, 
        error: function (err) {
          elements[i].innerHTML = 'error';
          console.error(err);
        }
      });
    }
  },

  first_letter( string ){
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  
  land_zeigen( modus, id){ 
    if(laendersuche.laendersuche_last_entry){ $(laendersuche.laendersuche_last_entry).hide();}
    
    if(id){
      $("#adressanzeige > div").each(function(s){ $(this).hide(); });
      
      if(modus =="map_reposition"){
        //Land in der Karte zentrieren
        if(laendersuche.laendermittelpunkte){
          contact_map.pan_map_to(laendersuche.laendermittelpunkte[id.substring(0, 2)][0],laendersuche.laendermittelpunkte[id.substring(0, 2)][1]);
          contact_map.map.setZoom(4);
        } else {
          laendersuche.set_laendermittelpunkte(id.substring(0, 2));
        }
      }

      contact_map.updateMarker(id);
                        
      var kennung = "#adresse_" + id.toUpperCase();

      if($(kennung)){
        $(kennung).show(); 
        $(kennung).removeClass("hidden");
        
        // ist die Adresse im Scope?
        var h = $( window ).height(); // ss document.viewport.getHeight();
        var viewport = h + $(window).scrollTop(); // wo ist der Viewport? ms 20122013
        
        var pos_y = 100; // ss ($(kennung).viewportOffset())[1];
        var hoehe = 100; // $(kennung).getHeight();
        
        var elementView = $(kennung).scrollTop()+pos_y; // Wo liegt das Element? ms 20122013
        if(((viewport > elementView)||(pos_y < 10)) &&( modus != "ohne_scrolling")){

          //new Effect.ScrollTo("adresse_" + id, { offset:-20 });
          $('html, body').animate({scrollTop: $(kennung).offset().top-100}, 1000); // ms 20122013
          
          // Bilder laden
          after_ajax.start(kennung);
        }
        
        // Land merken 
        laendersuche.laendersuche_last_entry = kennung;
      }	
    }
  },
  
  email_bauen(vorne, hinten){
    return "mailto:" + vorne + "@" + hinten;
  },
  
  /* ID-------------------
  leert das suchefeld bei Bedarf */
  
  focus( obj ){
    if(!laendersuche.laendersuche_last_entry){
      laendersuche.laendersuche_last_entry = obj.value;
      obj.value = "";	
    }
    return true; 
  },
  
  /* ID-------------------
  weist dem Suchfeld bei Bedarf den letzten Wert zu */
  
  blur(obj){
    if(obj.value == "" ){ 
      obj.value = laendersuche.laendersuche_last_entry;
      laendersuche.laendersuche_last_entry = false; 
    }
  },
  
  finden( obj ){
    var hoehe = $('#laenderliste').outerHeight();
    $("#laenderliste").css({ height: hoehe+"px" });
    var suchwert = first_letter(obj.value);
    
    $("#laenderliste div").each(function( i ){
      
      var id = this.id;
      var count = 0;

      $(this).find('li').each(function(i){
        if(this.title.match(suchwert)){ 
          $(this).show(); 
          count++;
        }
        else{ $(this).hide(); }
      });
    
      if(!count){ $(this).hide();}
      else{ $(this).show(); }
    });
  }
}

const Dropdown = {
  init: function() {
    $('#check-position').attr('disabled', true);
    let textElements = document.querySelectorAll('.text');
    let textelement = '';
    if(textElements.length > 1) {
      textElement = document.querySelector('[data-input-location]');
    }
    else {
      textElement = document.querySelector('.text');
    }
    textElement.addEventListener('input', function(e) {
      
      if (!String.prototype.startsWith) {
        String.prototype.startsWith = function(searchString, position) {
          position = position || 0;
          return this.indexOf(searchString, position) === position;
        };
      }

      var input = this.value;
      e.preventDefault();
      const matches = contact_map.countryList.liste.filter( function(s) { return s.name.toLowerCase().startsWith(input.toLowerCase()); });

      if(this.value) { 
        var results = [];
        matches.forEach(function(e){
          results.push(e.name);
        });
        if(results.length == 0){
          Dropdown.hide();
        } else {
          Dropdown.createSuggestions(results);
        } 
        
      } else {
        Dropdown.clear();
        Dropdown.hide();
      }

      const realMatch = contact_map.countryList.liste.find(function(s) { return s.name.toLowerCase() == input.toLowerCase(); });
      var placeholder = document.getElementById("standort").placeholder;
      if(realMatch != undefined && realMatch.name != placeholder){
        $('#check-position').attr('disabled', false); 
      } else {
        $('#check-position').attr('disabled', true);
      }
    });

    Dropdown.hide();

    document.querySelector('.dropdown-vertrieb').addEventListener('blur', function(e) {
      Dropdown.clear();
      Dropdown.hide();
    });
  },

  createSuggestions: function(elms) {
    Dropdown.clear();
    Dropdown.show();
    elms.forEach(function(elm) {
		const suggestion = document.createElement('div');
		suggestion.classList.add('suggestion');
		var test = document.createTextNode(elm);
		suggestion.appendChild(test);
		suggestion.addEventListener('click', function(e) {

			var placeholder = document.getElementById("standort").placeholder;
			if(placeholder != this.textContent){
        let textElements = document.querySelectorAll('.text');
        let textelement = '';
        if(textElements.length > 1) {
          textElement = document.querySelector('[data-input-location]');
        }
        else {
          textElement = document.querySelector('.text');
        }
    
				textElement.value = this.textContent;
				$('#check-position').attr('disabled', false);
			} else {
				textElement.value = "";
			}
			
			Dropdown.clear();
			Dropdown.hide();
		});
      	document.querySelector('.dropdown-vertrieb').appendChild(suggestion);
    });
  },
  
  clear: function(){
    const elm = document.querySelector('.dropdown-vertrieb');
    while(elm.firstChild) { elm.removeChild(elm.firstChild) };
  },

  show: function() {
	  const elm = document.querySelector('.dropdown-vertrieb');
	  elm.classList.add('-active');
  },

  hide: function() {
	  const elm = document.querySelector('.dropdown-vertrieb');
	  elm.classList.remove('-active');
  }
};