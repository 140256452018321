/*
  Dependencies:
  - window
  - $ (jQuery)
  - setShareLink()
  - ShareImageButton()
  - ShareImageButtonOnPic()

  Dependents:
  - after_ajax dist/struktur/js/global/erco.js
  - $(document).ready() dist/struktur/js/global/erco.js
  - loadOverlay - dist/struktur/js/global/lichtwissen.js
*/

// fullscreen slideshow
let fullscreen_slideshow_initialisiert = false;
const fullscreen_slideshow = {

  // gallery code in den body injecten. und links initialisieren
  init() {
    if (!fullscreen_slideshow_initialisiert) {
      fullscreen_slideshow_initialisiert = true;
      $('body').append('<!-- The Bootstrap Image Gallery lightbox, should be a child element of the document body --><div id="blueimp-gallery" class="blueimp-gallery blueimp-gallery-controls"><!-- The container for the modal slides --><div class="slides"></div><!-- Controls for the borderless lightbox --><h3 class="title"></h3><p class="description"></p><span class="prev"><span class="newicon-slide-left"></span></span><span class="next"><span class="newicon-slide-right"></span></span><span class="close"><span class="newicon-65-0 with-shadow"></span></span><ol class="indicator hidden-sm"></ol><!-- The modal dialog, which will be used to wrap the lightbox content --><div class="modal fade"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"><button type="button" class="close" aria-hidden="true">&times;</button><h4 class="modal-title"></h4></div><div class="modal-body next"></div><div class="modal-footer"><button type="button" class="btn btn-default pull-left prev"><i class="glyphicon glyphicon-chevron-left"></i>Previous</button><button type="button" class="btn btn-primary next">Next<i class="glyphicon glyphicon-chevron-right"></i></button></div></div></div></div></div>');
    }
    fullscreen_slideshow.init_links();
  },

  // links erstellen
  init_links() {
    // alle images die als fullscreen angezeigt werden können auslesen
    const $fullscreen_images = $('[data-fullscreen=1]');
    if ($fullscreen_images.length > 0) {
      // Share-Button platzieren und verwalten
      let shareImageButton = null;

      $('#blueimp-gallery').on('opened', () => {
        if (!shareImageButton) {
          shareImageButton = new ShareImageButton();
          shareImageButton.create(null, null);
        }
      }).on('slide', (event, index, slide) => {
        const img = $(slide).find('img').attr('src');
        if (!shareImageButton) {
          shareImageButton = new ShareImageButton();
          shareImageButton.create(img);
        } else {
          // shareImageButton.setUrl(img);
          // aktualisiert nicht wenn im Slider andere Bilder ausgewählt wurden
          // - Keno 24.01.2018
          shareImageButton.destroy();
          shareImageButton = new ShareImageButton();
          shareImageButton.create(img);
        }
      }).on('closed', () => {
        if (!shareImageButton) return;
        shareImageButton.destroy();
        shareImageButton = null;
      });

      // generate unique id
      let srcesString = '';
      $fullscreen_images.each(function concatSrc() {
        srcesString = `${srcesString},${$(this).attr('src')}`;
      });
      const uniqueId = `gallery_${$.md5(srcesString)}`;

      $fullscreen_images.each(function addLink() {
        const $this = $(this);

        // fullscreen images mit dem nötigen link ausstatten, falls noch nicht passiert
        if (!$this.hasClass('fullscreen_initialisiert')) {
          $this.addClass('fullscreen_initialisiert');

          // Template: lichtinspirationen.php - lazyload bilder ohne src (ss 20171017)
          let token = '';
          let presettedImageURL = null;
          if ($this.attr('src')) {
            token = $this.attr('src').split('/'); // src auslesen
          } else if ($this.css('background-image') !== 'none') {
            const backGroundImage = $this.css('background-image');
            presettedImageURL = backGroundImage.replace('url(', '').replace(')', '').replace(/"/gi, '');
            token = presettedImageURL.split('/');
          } else {
            token = $this.attr('data-flickity-lazyload').split('/'); // data-flickity-lazyload auslesen
          }
          let $gallerylink;
          if (!$this.attr('data-fullscreen-no-icon')) {
            if ($this.parent().hasClass('imgshift') || $this.get(0).hasAttribute('data-fullscreen-image')) {
              // Slider-Img (Kurzgeschichten) sollen fullscreen-icon bekommen (24.01.2018, Keno)
              $this.parent().append(`<span class="fullscreen-icon with-shadow slider-fullscreen-icon"><a href="" class="gallery-link" title="" data-gallery="#${uniqueId}" style="position:absolute;height:24px; width:24px;"></a></span>`);
              $gallerylink = $this.parent().children('.fullscreen-icon').children('.gallery-link');
            } else {
              $this.wrap(`<a href="" class="gallery-link" title="" data-gallery="#${uniqueId}"></a>`); // a tag um das bild legen
              $gallerylink = $this.parent('.gallery-link'); // a-tag im dom selektieren
              $gallerylink.append('<span class="fullscreen-icon with-shadow"></span>');
            }
          } else {
            $this.before(`<a href="" class="gallery-link" title="" data-gallery="#${uniqueId}" style="position:absolute;left:5px;z-index:0;height:0px; width:0px;"><img src="${$this.attr('src')}" style="display:none;"></a>`); // a tag um das bild legen
            $gallerylink = $this.prev('.gallery-link'); // a-tag im dom selektieren*/
          }

          const dateiname = token[token.length - 1];
          let hasBigImage = ($this.get(0).hasAttribute('data-fullscreen-big-image')) ? $this.attr('data-fullscreen-big-image') : false;

          // Nicht für Bilder im Header & Bilder aus Indexaktionen (Flickity-Slideshows) ss 20171017
          if (dateiname.match(/-rep-/) && dateiname.match(/-rep\./) && dateiname.match(/_aktion_/)) {
            hasBigImage = true;
          }

          if (hasBigImage === true) {
            token[token.length - 1] = `gross--${dateiname}`; // src anpassen
          }

          $gallerylink.attr('href', token.join('/')); // src verlinken

          let url = '';
          if (presettedImageURL !== null) {
            url = presettedImageURL;
          } else {
            const { host } = window.location;
            const { protocol } = window.location;
            url = `${protocol}//${host}${token.join('/')}`;
          }
          const shareImageButtonOnPic = new ShareImageButtonOnPic();
          const shareButton = shareImageButtonOnPic.create(url);
          let parent;

          if (!$this.attr('data-fullscreen-no-icon')) {
            if ($this.parent().hasClass('imgshift') || $this.get(0).hasAttribute('data-fullscreen-image')) {
              parent = $this.parent();
              $this.before(shareButton);
            } else {
              parent = $this.parent().parent();
              $this.parent().before(shareButton);
            }

            const nav = parent.find('nav');
            nav.addClass('fix-height');

            nav.on('click', () => {
              const changeButton = nav.children().children().find('span').first();
              if (!nav.children().children().hasClass('-active')) {
                nav.children().children().addClass('-active');
                changeButton.removeClass('newicon-7-0').addClass('newicon-65-0');
                changeButton.removeClass('with-shadow');
              } else {
                nav.children().children().removeClass('-active');
                changeButton.addClass('newicon-7-0').removeClass('newicon-65-0');
                changeButton.addClass('with-shadow');
              }
            });
          } else {
            parent = $this.parent();
            $this.before(shareButton);

            const nav = parent.find('nav');

            nav.on('click', () => {
              const changeButton = nav.children().children().find('span').first();
              if (!nav.children().children().hasClass('-active')) {
                nav.children().children().addClass('-active');
                changeButton.removeClass('newicon-7-0').addClass('newicon-65-0');
              } else {
                nav.children().children().removeClass('-active');
                changeButton.addClass('newicon-7-0').removeClass('newicon-65-0');
              }
            });
          }
        } // !hasclass('fullscreen_initialisiert')
      }); // fullscreen image each

      // eventlistener for data-share-link

      const $sharebuttons = $('[data-share-link]');

      $sharebuttons.each(function shareButtonClick() {
        const $this = $(this);

        $this.on('click', () => {
          let shareInformation = $this.attr('data-share-link');
          shareInformation = JSON.parse(shareInformation);

          setShareLink(
            shareInformation.imgUrl,
            shareInformation.description,
            shareInformation.plattform,
            shareInformation.title,
          );
        });
      });
    } // if images available
  }, // init links

}; // fullscreen slideshow
