/**
 * Class representing a YouTube video embedded in the page.
 */
class YouTubeVideo {
  /**
   * Create a YouTubeVideo instance.
   * @param {HTMLElement} element - The HTML element that contains the video data and elements.
   */
  constructor(element) {
    /**
     * The wrapper element containing the video.
     * @type {HTMLElement}
     */
    this.wrapperVideo = element;

    /**
     * The element representing the video poster.
     * @type {HTMLElement}
     */
    this.poster = this.wrapperVideo.querySelector('[data-js-video-poster]');

    /**
     * The button element to initiate video playback.
     * @type {HTMLElement}
     */
    this.playButton = this.wrapperVideo.querySelector('[data-js-video-init]');

    if (!this.playButton || !this.poster || !this.wrapperVideo) return;

    try {
      /**
       * The configuration object parsed from the data attribute.
       * @type {Object}
       */
      this.config = JSON.parse(this.wrapperVideo.getAttribute('data-js-video'));
    } catch (e) {
      console.error('Error parsing JSON', e);
      return;
    }

    /**
     * The ID of the YouTube video.
     * @type {string|null}
     */
    this.videoId = this.config?.id || null;
    if (!this.videoId) return;

    this.init();
  }

  /**
   * Initialize the YouTube video by setting up event listeners.
   */
  init() {
    this.playButton.addEventListener('click', () => {
      this.loadVideo();
    });
  }

  /**
   * Load the YouTube video by replacing the poster with an iframe.
   */
  loadVideo() {
    this.poster.classList.add('d-n');
    this.wrapperVideo.insertAdjacentHTML('beforeend', `
      <iframe
        width="100%"
        height="100%"
        src="https://www.youtube-nocookie.com/embed/${this.videoId}?autoplay=1&rel=0&modestbranding=1"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    `);
  }

  /**
   * Initialize all YouTubeVideo instances on the page.
   */
  static initYouTubeVideos() {
    const videoElements = document.querySelectorAll('[data-js-video]');
    videoElements.forEach((element) => {
      new YouTubeVideo(element);
    });
  }
}

// Initialize YouTubeVideo instances when the DOM is fully loaded.
document.addEventListener('DOMContentLoaded', () => {
  YouTubeVideo.initYouTubeVideos();
});
